<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <AdminHeader
                    :registryTitle="$t('global:roles')"
                    :registryUrl="'roles'"
                    @clearSearch="clearTable"
                />
                <Table
                    :headers="headers"
                    :length="getRolesTable.pages"
                    :page="getRolesTable.page"
                    :defaultFields="getRoleSearchFields.searchFields"
                    @search="setSearch"
                    @change-page="setPage"
                >
                    <Columns
                        slot="columns"
                        :items="getRolesTable.items"
                        @details="showData"
                    />
                </Table>
                <Modal
                    :title="$t('global:addition')"
                    :open="open"
                    @close="closeModal"
                    :width="'600'"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        slot="buttons"
                        @closeModal="open = false"
                        :action="action"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                @click="createModal"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-briefcase-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('roles:createRole') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Buttons from './../../../components/Admin/Roles/Modal/Buttons'
import Header from './../../../components/Admin/Header/Header'
import Columns from './../../../components/Admin/Roles/Table/Columns'
import AEContent from './../../../components/Admin/Roles/Modal/Content'
import store from './../../../store/index'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global', 'roles'] },
    components: {
        Header,
        Columns,
        AEContent,
        Buttons,
    },

    methods: {
        ...mapActions(['fetchRole', 'fetchRolesTable', 'fetchRoleRegistries']),
        ...mapMutations(['clearRoleModal', 'setRolesTable', 'clearRolesTable']),
        closeModal() {
            this.clearRoleModal()
            this.open = false
        },
        showData(id) {
            this.action = 'edit'
            this.fetchRole({ id })
            this.open = true
        },
        setSearch(search) {
            this.setRolesTable({ search, page: 1 })
            this.fetchRolesTable()
        },
        setPage(page) {
            this.setRolesTable({ page })
            this.fetchRolesTable()
        },
        createModal() {
            this.fetchRoleRegistries()
            this.clearRoleModal()
            this.action = 'create'
            this.open = true
        },
        clearTable() {
            this.clearRolesTable()
            this.fetchRolesTable()
        },
    },
    computed: {
        ...mapGetters(['getRolesTable', 'getRoleSearchFields']),
    },

    data() {
        return {
            open: false,
            action: '',
            headers: [
                {
                    text: this.$t('roles:name'),
                    value: 'name',
                    width: '55%',
                },
                {
                    text: this.$t('roles:createdBy'),
                    value: 'createdBy',
                    width: '15%',
                },
                {
                    text: this.$t('roles:createdAt'),
                    value: 'createdAt',
                    width: '10%',
                },
                {
                    text: this.$t('roles:updatedAt'),
                    value: 'updatedAt',
                    width: '10%',
                },
                {
                    value: 'action',
                    width: '10%',
                    text: '',
                    sortable: false,
                },
            ],
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/roles/:id')
            store.commit('clearRolesTable')
        store.dispatch('fetchRolesTable').then(() => next())
    },
}
</script>
