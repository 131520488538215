<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                data-cy="stepper"
                                :rules="[
                                    () => getRoleErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{ $t('roles:informations') }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">                            
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                        data-cy="name"
                                            :label="`${$t('roles:name')}*`"
                                            v-model="getRoles.name"
                                            :error-messages="
                                                getRoleErrors.stepOne.fields.name
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-list
                                            two-line
                                            max-height="400"
                                            class="list"
                                        >
                                            <v-list-item
                                                v-for="item in getRolesRegistries"
                                                :key="item.url"
                                                class="pa-0"
                                            >
                                                <v-list-item-avatar
                                                    class="ma-0 pa-0"
                                                >
                                                    <v-icon>{{
                                                        item.icon
                                                    }}</v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content>
                                                    <v-list-item-title
                                                        v-text="item.name"
                                                    ></v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        <v-chip
                                                            v-for="operation in operations"
                                                            :key="operation"
                                                            label
                                                            :color="
                                                                item.operations &&
                                                                item.operations.includes(
                                                                    operation
                                                                )
                                                                    ? 'primary'
                                                                    : 'grey darken-1'
                                                            "
                                                            x-small
                                                            class="mr-1 white--text"
                                                            @click="
                                                                chooseOperation(
                                                                    {
                                                                        operation,
                                                                        url:
                                                                            item.url,
                                                                    }
                                                                )
                                                            "
                                                        >
                                                            {{ operation }}
                                                        </v-chip>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['roles'] },
    data() {
        return {
            step: 1,
            operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        }
    },
    computed: {
        ...mapGetters(['getRoles', 'getRolesRegistries', 'getRoleErrors']),
    },
    methods: {
        ...mapActions(['chooseOperation']),
    },
}
</script>

<style scoped>
.list {
    overflow-y: scroll;
}
</style>