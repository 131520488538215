var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"data-cy":"stepper","rules":[
                                () => _vm.getRoleErrors.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('roles:informations')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"data-cy":"name","label":`${_vm.$t('roles:name')}*`,"error-messages":_vm.getRoleErrors.stepOne.fields.name},model:{value:(_vm.getRoles.name),callback:function ($$v) {_vm.$set(_vm.getRoles, "name", $$v)},expression:"getRoles.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{staticClass:"list",attrs:{"two-line":"","max-height":"400"}},_vm._l((_vm.getRolesRegistries),function(item){return _c('v-list-item',{key:item.url,staticClass:"pa-0"},[_c('v-list-item-avatar',{staticClass:"ma-0 pa-0"},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',_vm._l((_vm.operations),function(operation){return _c('v-chip',{key:operation,staticClass:"mr-1 white--text",attrs:{"label":"","color":item.operations &&
                                                            item.operations.includes(
                                                                operation
                                                            )
                                                                ? 'primary'
                                                                : 'grey darken-1',"x-small":""},on:{"click":function($event){return _vm.chooseOperation(
                                                                {
                                                                    operation,
                                                                    url:
                                                                        item.url,
                                                                }
                                                            )}}},[_vm._v(" "+_vm._s(operation)+" ")])}),1)],1)],1)}),1)],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }