<template>
    <tbody>
        <tr v-for="(item, index) in items" :key="index">
            <td class="truncate">{{ item.name }}</td>
            <td class="truncate">
                <span>{{ setCreatedBy(item) }}</span>
            </td>
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$moment(item.createdAt).format(`DD-MM-YYYY`)"
                    :secondLine="$moment(item.createdAt).format(`HH:mm`)"
                />
            </td>
            <td class="truncate">
                <DoubleLine
                    :firtstLine="$moment(item.updatedAt).format(`DD-MM-YYYY`)"
                    :secondLine="$moment(item.updatedAt).format(`HH:mm`)"
                />
            </td>
            <td class="d-flex align-center justify-end">
                <v-btn icon small @click="openConfirmModal(item._id)">
                    <v-icon color="red" small>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                    data-cy="details"
                    small
                    @click="$emit('details', item._id)"
                    icon
                >
                    <v-icon color="secondary" small>mdi-magnify</v-icon>
                </v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main">
                <ConfirmContent />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="remove()"
                        >{{ $t('remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import { mapActions } from 'vuex'
import ConfirmContent from '../ConfirmModal/ConfirmContent'
export default {
    props: ['items'],
    data: () => ({
        openConfirm: false,
        removeItemID: '',
    }),
    components: {
        ConfirmContent,
    },
    methods: {
        ...mapActions(['deleteRole']),
        setCreatedBy(item) {
            return item.createdBy
                ? `${item.createdBy.name} ${item.createdBy.lastname}`
                : ''
        },
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async remove() {
            let result = await this.deleteRole({ id: this.removeItemID })
            if (result) this.openConfirm = false
        },
    },
}
</script>
